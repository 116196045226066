<template>
  <div class="page">
    <div class="page__header row justify-content-center">
      <div class="col-12 col-xs-11 page__header__content">
        <h1 class="page__header__title">{{ $t('navSideProjects') }}</h1>
      </div>
      <div class="col col-xs-11 page__header__tab">
        <div
          class="page__header__tab__item"
          v-on:click="setTab(newProjectsTab)"
          :class="{ 'page__header__tab__item--active': currentTab == newProjectsTab }"
        >
          {{ $t('newProjects') }}
          <badge
              :content="totalNew"
              :badgeClass="[{ 'badge-white': currentTab != newProjectsTab }, { 'badge-gray': currentTab == newProjectsTab }]"
          />
        </div>
        <div
          class="page__header__tab__item"
          v-on:click="setTab(ongoingProjectsTab)"
          :class="{ 'page__header__tab__item--active': currentTab == ongoingProjectsTab }"
        >
          {{ $t('ongoingProjects') }}
          <badge
            :content="totalOngoing"
            :badgeClass="[
              { 'badge-white': currentTab != ongoingProjectsTab },
              { 'badge-gray': currentTab == ongoingProjectsTab }
            ]"
          />
        </div>
        <div
          class="page__header__tab__item"
          v-on:click="setTab(closedProjectsTab)"
          :class="{ 'page__header__tab__item--active': currentTab == closedProjectsTab }"
        >
          {{ $t('completedProjects') }}
          <badge
            :content="totalClosed"
            :badgeClass="[{ 'badge-white': currentTab != closedProjectsTab }, { 'badge-gray': currentTab == closedProjectsTab }]"
          />
        </div>
        <div
          class="page__header__tab__item page__header__tab__item--search"
          v-on:click="setTab(searchProjectsTab)"
          :class="{ 'page__header__tab__item--active': currentTab == searchProjectsTab }"
        ></div>
      </div>
    </div>
    <div class="page__body page__body--minheight row justify-content-center">
      <div class="col-12 col-xs-11 page__body__col">
          <projects-list
            v-on:load="newProjectsLoaded"
            v-show="currentTab == newProjectsTab"
            :type="newProjectsTab"
            :totalCountCallback="totalNewCallback"
          ></projects-list>

          <projects-list
            v-on:load="ongoingProjectsLoaded"
            v-show="currentTab == ongoingProjectsTab"
            :type="ongoingProjectsTab"
            :totalCountCallback="totalOngoingCallback"
          ></projects-list>

          <projects-list
            v-on:load="closedProjectsLoaded"
            v-show="currentTab == closedProjectsTab"
            :type="closedProjectsTab"
            :totalCountCallback="totalClosedCallback"
          ></projects-list>

        <loading-wrapper :isLoading="searchProjectsLoading" v-show="currentTab == searchProjectsTab">
          <h2>{{ $t('projectsSearchTitle') }}</h2>
          <p>{{ $t('projectsSearchDesc') }}</p>
          <div class="row">
            <div class="col-xs-8 col-sm-6 col-md-4">
              <form @submit.prevent="searchProjects" id="search">
                <search-input-gcr name="query" v-model="searchQuery" :placeholder="$t('projectSearch')" />
              </form>
            </div>
          </div>
          <div v-show="searchQuery">
            <h3 class="bolder">{{ $t('projectsSearchResultTitle') }}</h3>
            <projects-list
              v-on:load="searchProjectsLoaded"
              v-show="currentTab == searchProjectsTab"
              :type="searchProjectsTab"
              :filter-key="searchQuery"
            ></projects-list>
          </div>
        </loading-wrapper>

        <!-- // -->
      </div>
    </div>
  </div>
</template>

<script>
import { Consts } from '@/_helpers'
import ProjectsList from '@/components/organism/ProjectsList'
import LoadingWrapper from '@/components/organism/LoadingWrapper'
import SearchInputGcr from '@/components/atoms/SearchInput'
import Badge from '@/components/atoms/Badge'

require('../utilities/page')
require('../utilities/form')

export default {
  components: { ProjectsList, LoadingWrapper, SearchInputGcr, Badge },
  data() {
    return {
      newProjectsTab: Consts.NEW_PROJECTS,
      ongoingProjectsTab: Consts.ONGOING_PROJECTS,
      closedProjectsTab: Consts.CLOSED_PROJECTS,
      searchProjectsTab: Consts.SEARCH_PROJECTS,
      searchQuery: '',
      totalNew: '',
      totalOngoing: '',
      totalClosed: '',
      newProjectsLoading: true,
      ongoingProjectsLoading: true,
      closedProjectsLoading: true,
      searchProjectsLoading: true,
      currentTab: this.$route.query.ct ? this.$route.query.ct : Consts.NEW_PROJECTS
    }
  },
  methods: {
    setTab(tab) {
      this.currentTab = tab
    },
    totalNewCallback(total) {
      this.totalNew = total
    },
    totalOngoingCallback(total) {
      this.totalOngoing = total
    },
    totalClosedCallback(total) {
      this.totalClosed = total
    },
    newProjectsLoaded: function() {
      this.newProjectsLoading = false
    },
    ongoingProjectsLoaded: function() {
      this.ongoingProjectsLoading = false
    },
    closedProjectsLoaded: function() {
      this.closedProjectsLoading = false
    },
    searchProjectsLoaded: function() {
      this.searchProjectsLoading = false
    },
    searchProjects: function() {
      //nothing to do
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../utilities/config';
.page__header__title {
  &::after {
    background-image: url(../assets/home_gray.svg);
  }
}
.page__header__tab__item {
  &--search {
    display: inline-block;
    background-image: url(../assets/magnifying_glass_dark_gray.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1.3em 1.3em;
    width: 60px;
  }
}
</style>
