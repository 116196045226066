<template>
  <nav class="m-paging" v-if="pageCount > 1">
    <ul class="pagination">
      <li class="page-item" v-bind:class="{ disabled: pageIndex <= 1 }">
        <a class="page-link" v-on:click="prevPage">{{ $t('previous') }}</a>
      </li>
      <li class="page-item" v-bind:class="{ active: pageIndex === p.id }" v-for="p in pagesArray" :key="p.id">
        <a class="page-link" v-bind:class="{ disabled: p.text === morePages }" v-on:click="setPage(p)">{{ p.text }}</a>
      </li>
      <li class="page-item" v-bind:class="{ disabled: pageIndex >= pageCount }">
        <a class="page-link" v-on:click="nextPage">{{ $t('next') }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    callback: {},
    pageCount: Number,
    pageItemCount: {
      default() {
        return 5
      }
    }
  },
  data() {
    return {
      pageIndex: 1,
      morePages: '...'
    }
  },
  computed: {
    pagesArray() {
      let intArr = []
      let index = 0

      if (this.pageIndex > Math.floor(this.pageItemCount / 2) + 1) {
        intArr.push({ id: 1, text: 1 })
        if (this.pageIndex - (Math.floor(this.pageItemCount / 2) + 2) === 1) {
          intArr.push({
            id: this.pageIndex - (Math.floor(this.pageItemCount / 2) + 1),
            text: this.pageIndex - (Math.floor(this.pageItemCount / 2) + 1)
          })
        } else if (this.pageIndex - (Math.floor(this.pageItemCount / 2) + 1) > 1) {
          intArr.push({ id: 1001, text: this.morePages })
        }
      }

      for (index = Math.max(1, this.pageIndex - Math.floor(this.pageItemCount / 2)); index < this.pageIndex; index++) {
        intArr.push({ id: index, text: index })
      }

      intArr.push({ id: this.pageIndex, text: this.pageIndex })

      let addedCount = 0
      for (index = this.pageIndex + 1; index < this.pageCount && addedCount < Math.floor(this.pageItemCount / 2); index++) {
        intArr.push({ id: index, text: index })
        addedCount++
      }

      if (this.pageIndex + addedCount < this.pageCount) {
        if (this.pageIndex + Math.floor(this.pageItemCount / 2) + 1 === this.pageCount - 1) {
          intArr.push({
            id: this.pageIndex + Math.floor(this.pageItemCount / 2) + 1,
            text: this.pageIndex + Math.floor(this.pageItemCount / 2) + 1
          })
        } else if (this.pageIndex + addedCount < this.pageCount - 1) {
          intArr.push({ id: 1002, text: this.morePages })
        }
        intArr.push({ id: this.pageCount, text: this.pageCount })
      }

      return intArr
    }
  },
  methods: {
    setPage(page) {
      if (page.text != this.morePages) {
        this.pageIndex = page.id
        this.callback(this.pageIndex)
      }
    },
    nextPage() {
      this.pageIndex++
      this.callback(this.pageIndex)
    },
    prevPage() {
      this.pageIndex--
      this.callback(this.pageIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
@import '../../utilities/sass/mixins';
@import 'node_modules/bootstrap/scss/pagination';

.m-paging {
  @include rm-child-margin;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  .page-item {
    cursor: pointer;
    &.disabled {
      .page-link {
        background-color: transparent;
      }
      cursor: not-allowed;
    }
    .page-link {
      border: 0;
      background-color: transparent;
      border-radius: 0.25rem;
      &:hover {
        background-color: gray('gray-200');
      }
    }
    &.active {
      .page-link {
        background-color: theme-color('primary');
        border-color: theme-color('primary');
      }
    }
  }
}
</style>
