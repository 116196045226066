<template>
  <div class="a-search-input">
    <input
      :type="type"
      v-bind:value="value"
      :id="id"
      class="form-control"
      :class="{ 'is-invalid': invalid }"
      @input="onInput"
      :disabled="disabled"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
export default {
  directives: {},
  props: {
    invalidText: {},
    value: {},
    submitted: {},
    disabled: {},
    placeholder: {
      default() {
        return ''
      }
    },
    optional: {
      default() {
        return false
      }
    },
    type: {
      default() {
        return 'text'
      }
    },
    id: {
      default() {
        return `input-${this._uid}`
      }
    }
  },
  computed: {
    invalid() {
      return this.invalidText && this.submitted && !this.value
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
.a-search-input {
  position: relative;
  &::after {
    content: '';
    width: 22px;
    height: 22px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url(../../assets/magnifying_glass_dark_green.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
  input {
    padding-right: 42px;
  }
}
</style>
