<template>
  <div>
    <loading-wrapper :isLoading="gridLoading">
      <grid-gcr v-on:update="updateProjectsList" :data="projects" :columns="columns" v-model="sort"></grid-gcr>
    </loading-wrapper>
    <paging-gcr :callback="changePage" :pageCount="pageCount"></paging-gcr>
  </div>
</template>

<script>
import LoadingWrapper from '@/components/organism/LoadingWrapper'
import GridGcr from '@/components/molecules/Grid'
import PagingGcr from '@/components/molecules/Paging'
import { Consts } from '@/_helpers/consts'
import { Projects } from '@/_helpers/projects'
import getProjectsByStatus from '@/graphql/accreditations/projects/getProjectsByStatus.graphql'
import getProjectsByQuery from '@/graphql/accreditations/projects/getProjectsByQuery.graphql'

export default {
  components: { GridGcr, PagingGcr, LoadingWrapper },
  props: {
    totalCountCallback: {
      default: null
    },
    type: String,
    filterKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      gridLoading: false,
      projects: [],
      pageIndex: 1,
      pageCount: 0,
      sort: {
        direction: '',
        key: ''
      }
    }
  },
  created() {
    if (this.type === Consts.NEW_PROJECTS) {
      this.sort.direction = 'ASC'
      this.sort.key = 'projectNameColumn'
    } else {
      this.sort.direction = 'DESC'
      this.sort.key = 'projectRegisterDate'
    }

    if (this.type === Consts.SEARCH_PROJECTS) {
      this.$apollo.queries.searchprojets.skip = false
    } else {
      this.$apollo.queries.getprojects.skip = false
    }
  },
  computed: {
    columns() {
      return featureNewProjectCreationColumns(this.type)
    }
  },
  apollo: {
    searchprojets: {
      query: getProjectsByQuery,
      throttle: 500,
      skip: true,
      variables() {
        return {
          id: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
          page: this.pageIndex,
          query: this.filterKey
        }
      },
      update(data) {
        this.projectsLoaded(data.accreditation.searchProjects, data.accreditation.id)
      },
      watchLoading(isLoading) {
        this.gridLoading = isLoading
      }
    },
    getprojects: {
      query: getProjectsByStatus,
      skip: true,
      variables() {
        return {
          id: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
          page: this.pageIndex,
          search: {
            workflowStep: this.type,
            sortOption: this.mapColumnToApi(this.sort.key),
            sortDirection: this.sort.direction
            // TODO filterKey quand Bee le supportera
          }
        }
      },
      update(data) {
        this.projectsLoaded(data.accreditation.projects, data.accreditation.id)
      },
      watchLoading(isLoading) {
        this.gridLoading = isLoading
      }
    }
  },
  methods: {
    updateProjectsList() {
      this.$apollo.queries.getprojects.refetch()
    },
    projectsLoaded(projects, accreditationId) {
      if (this.totalCountCallback !== null) {
        this.totalCountCallback(projects.totalCount)
      }
      this.pageCount = projects.totalPages
      this.projects = Projects.toGrid(projects.items, accreditationId)
      this.$emit('load')
    },
    mapColumnToApi(col) {
      switch (col) {
        case 'projectNameColumn':
          return 'NAME'
        case 'projectRegisterDate':
          return 'REGISTRATION_DATE'
        case 'projectRegisterNumber':
          return 'REGISTRATION_NUMBER'
        case 'projectStatus':
          return 'STATUS'
      }
    },
    setTab(tab) {
      this.currentTab = tab
    },
    changePage(index) {
      this.pageIndex = index
    }
  }
}

function featureNewProjectCreationColumns(type) {
  if (type === Consts.NEW_PROJECTS) {
    return [
      { sortable: false, name: 'projectCreationDate' },
      { sortable: false, name: 'projectNameColumn' },
      { sortable: false, name: 'projectAddress' },
      { sortable: false, name: 'projectDescription' },
      { sortable: false, name: 'projectStatus' },
      { sortable: false, name: 'projectActions' },
      { sortable: false, name: 'projectButtons' }
    ]
  }

  if (type === Consts.ONGOING_PROJECTS || type === Consts.CLOSED_PROJECTS) {
    return [
      { sortable: true, name: 'projectRegisterDate' },
      { sortable: true, name: 'projectRegisterNumber' },
      { sortable: true, name: 'projectNameColumn' },
      { sortable: false, name: 'projectAddress' },
      { sortable: false, name: 'projectDescription' },
      { sortable: true, name: 'projectStatus' },
      { sortable: false, name: 'projectActions' }
    ]
  }

  if (type === Consts.SEARCH_PROJECTS) {
    return [
      { sortable: false, name: 'projectRegisterDate' },
      { sortable: false, name: 'projectRegisterNumber' },
      { sortable: false, name: 'projectNameColumn' },
      { sortable: false, name: 'projectAddress' },
      { sortable: false, name: 'projectDescription' },
      { sortable: false, name: 'projectStatus' },
      { sortable: false, name: 'projectActions' },
      { sortable: false, name: 'projectButtons' }
    ]
  }
  throw 'Project type not implemented'
}
</script>
